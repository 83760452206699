<template>
  <v-container fluid>
    <v-tabs v-model="tab_details">
      <v-tab dense :value="0">BODAS</v-tab>
      <v-tab dense :value="1">COMUNIONES</v-tab>
      <v-tab dense :value="2">EVENTOS Y CORPORATIVOS</v-tab>
      <v-spacer></v-spacer>
      <v-tab dense :value="3">MODIFICACIONES</v-tab>
      <v-tab dense :value="4">CARTAS</v-tab>
    </v-tabs>
    <v-tabs-items
      style="font-size: 0.9rem;flex-grow: 1;"
      class="py-1"
      v-model="tab_details"
    >
      <v-tab-item :value="0">
        <v-card-title class="primary--text text-h4 font-weight-bold pb-0">PRICINGS</v-card-title>
        <ListadoPricings @reload="reload" :loading="loading" :cartas="cartas" :modificaciones="modificaciones" :espacios="espacios" :tipoPpto="tipoPpto" :pricings="pricings.filter(p => p.tipo == 1)" :tipo="1" :familias="familias"/>
        <!-- <v-icon style="width: 100vw;height: 88vh;color:var(--v-secondary-base)" size="500">mdi-ring</v-icon> -->
      </v-tab-item>
      <v-tab-item :value="1">
        <v-card-title class="primary--text text-h4 font-weight-bold">PRICINGS</v-card-title>
        <ListadoPricings @reload="reload" :loading="loading" :cartas="cartas" :modificaciones="modificaciones" :espacios="espacios" :tipoPpto="tipoPpto" :pricings="pricings.filter(p => p.tipo == 2)" :tipo="2" :familias="familias"/>
      </v-tab-item>
      <v-tab-item :value="2">
        <v-card-title class="primary--text text-h4 font-weight-bold">PRICINGS</v-card-title>
        <ListadoPricings @reload="reload" :loading="loading" :cartas="cartas" :modificaciones="modificaciones" :espacios="espacios" :tipoPpto="tipoPpto" :pricings="pricings.filter(p => p.tipo == 3)" :tipo="3" :familias="familias"/>
      </v-tab-item>
      <v-tab-item :value="3">
        <v-card-title class="primary--text text-h4 font-weight-bold">MODIFICACIONES</v-card-title>
        <ListadoModificaciones @reload="reload" :loading="loading" :modificaciones="modificaciones" :familias="familias"/>
      </v-tab-item>
      <v-tab-item :value="4">
        <v-card-title class="primary--text text-h4 font-weight-bold">CARTAS</v-card-title>
        <ListadoCartas @reload="reload" :loading="loading" :espacios="espacios" :cartas="cartas"/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  components: {
    ListadoModificaciones: () => import("../components/modificaciones/Modificaciones.vue"),
    ListadoCartas: () => import("../components/cartas/Cartas.vue"),
    ListadoPricings: () => import("../components/pricings/Pricings.vue"),
  },
  data(){
    return {
      tab_details: 0,
      value: 0,
      keyHelper: 0,
      loading: true,
      modificaciones: [],
      cartas: [],
      pricings: [],
      familias: [
        {title : "APERITIVO DE BIENVENIDA", val: 1},
        {title : "MENÚ NUPCIAL", val: 2},
        {title : "BODEGA", val: 3},
        {title : "COMPLEMENTOS", val: 4},
        {title : "RECENA", val: 5},
        {title : "FIN DE FIESTA", val: 6},
        {title : "COMPLEMENTOS", val: 7},
      ],
      espacios:[
        // { nombreEspacio: "PISCIS",        siglas : "PIS" ,idEspacio : 1},
        // { nombreEspacio: "HOTEL",         siglas : "HOT" ,idEspacio : 2},
        { nombreEspacio: "MASFARRE (P+H)",      siglas : "MAS" ,idEspacio : 3},
        { nombreEspacio: "LALANNE",       siglas : "LAL" ,idEspacio : 4},
        { nombreEspacio: "LAUS",          siglas : "LAUS" ,idEspacio : 5},
        { nombreEspacio: "MAS DE PONENT", siglas : "MDP" ,idEspacio : 7},
        { nombreEspacio: "PALACIO",       siglas : "PAL" ,idEspacio : 8},
        { nombreEspacio: "CATERING",      siglas : "CAT" ,idEspacio : 6}
      ],
      tipoPpto:[
        {title : "PRESUPUESTO", siglas: "PPTO", val : 1},
        {title : "PRUEBA BODA", siglas: "PB", val : 2},
        {title : "REVISIÓN PB", siglas: "REV", val : 3}
      ],
    }
  },
  methods:{
    async reload(){
      this.loading = true
      await Promise.all([
        this.getModificaciones(),
        this.getCartas(),
        this.getPricings(),
      ])
      this.loading = false
    },
    async getModificaciones(){
      this.keyHelper++;
      const { data } = await axios("/modificaciones");
      this.modificaciones = data;
    },
    async getCartas(){
      this.keyHelper++;
      const { data } = await axios("/cartas");
      this.cartas = data;
      this.cartas.forEach(carta => {
        carta.espacioShow = this.espacios.find(e => e.idEspacio == carta.idEspacio)?.nombreEspacio
      });
    },
    async getPricings(){
      this.keyHelper++;
      const { data } = await axios("/pricings");
      this.pricings = data;
      this.pricings.forEach(carta => {
        carta.espacioShow = (this.espacios.find(e => e.idEspacio == carta.idEspacio)?.nombreEspacio || 'POR DEFINIR')
        carta.cartaShow = carta.idCarta ? ((this.cartas.find(c => c.idCarta == carta.idCarta)?.nombre || '') + " " + (this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == carta.idCarta)?.idEspacio)?.nombreEspacio || '') + " " + (this.cartas.find(c => c.idCarta == carta.idCarta)?.year || '')) : 'POR DEFINIR'
        carta.pptoShow = (this.tipoPpto.find(c => c.val == (carta.tipoPpto || 1))?.title)
      });
    },
  },
  mounted(){
    this.reload()
  }
};
</script>

<!-- 
  DELETE FROM modificaciones;
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET DE BIENVENIDA",5.00,0.00,-4.50,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET TEMÁTICO",5.00,0.00,-4.50,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET ESPÉCTACULO - PULPEIRO",7.50,3.50,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET ESPÉCTACULO - MARINERO (COMPLETO)",8.50,4.50,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET ESPÉCTACULO - AHUMADOS",6.50,2.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET ESPÉCTACULO - PURO SHOW EN APERITIVO (CHULETON VACA MADURA)",9.50,5.50,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BUFFET ESPÉCTACULO - PURO SHOW EN BODA TIPO COCTEL (CHULETON VACA MADURA)",13.00,9.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("OTRAS DELICATESSEN",2.50,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BAÑERA CERVEZAS NACIONALES",1.50,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BAÑERA CERVEZAS INTERNACIONALES (OJO TIPO)",3.00,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("CAVA APERITIVO",0.00,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("VINO APERITIVO (BLANCO, TINTO Y ROSADO)",0.00,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("REFRESCOS Y AGUAS (QUITAR)",0.00,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("CERVEZAS (QUITAR)",0.00,0.00,0.00,1);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("SALMOREJO TOMATE ROSA",7.50,-6.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("SOPA CARABINEROS",9.50,-4.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("TARTAR ATÚN Y SOPA DE TOMATE",9.50,-4.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("PULPO BRASEADO",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("MERLUZA CON MARINERA DE SEPIA",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BACALAO 70º",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LUBINA RAGOUT AJOS Y AJETES",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("RODABALLO",0.00,6.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("MERLUZA CON MARINERA DE SEPIA",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BACALAO 70º",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LUBINA RAGOUT AJOS Y AJETES",0.00,4.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("RODABALLO  CON CHIPIRONES",0.00,6.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("ENSALADA DE BOGAVANTE  (MEDIO)",0.00,10.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("ENSALADA DE BOGAVANTE",0.00,16.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BOGAVANTE GALLEGO ASADO",0.00,16.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LANGOSTA IMPERIAL (MEDIA)",0.00,16.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("SORBETES 2024",2.50,0.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("SOLOMILLO DE TERNERA",0.00,7.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("SOLOMILLO DE TERNERA CON FOIE",0.00,8.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LECHAZO DE CASTILLA",0.00,9.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("PALETILLA DE CABRITO",0.00,9.50,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("POSTRE CARTA BODAS (QUITAR)",0.00,0.00,-4.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("MISTERIO DE CHOCOLATE",0.00,3.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BOLA HELADO",2.50,0.00,0.00,2);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("VINO BLANCO CARTA (QUITAR)",0.00,0.00,-1.50,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("ENATE 234",0.00,2.00,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LAURA LALANNE",0.00,2.50,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("VDV GEWURZTRAMINER",0.00,2.50,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("VINO TINTO CARTA (QUITAR)",0.00,0.00,-1.50,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("ENATE CRIANZA",0.00,2.00,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LALANNE CRIANZA",0.00,2.00,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("OTROS CRIANZAS",0.00,2.00,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("CAVA CARTA (QUITAR)",0.00,0.00,-2.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("ANNA CODORNIU o AGUSTÍ TORELLO",0.00,2.50,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("LUCRECIA LALANNE",0.00,3.00,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("JUVE & CAMPS RESERVA FAMILIA",0.00,3.50,0.00,3);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("AGUA MINERAL",0.00,0,-2.00,4);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("RECENA NORMAL",0.00,0.00,-4.50,5);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("RECENA CATERING",6.50,0.00,0.00,5);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("PIZZAS o BURGER o TORTILLAS o HOTDOGS",0.00,2.00,0.00,5);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BARBACOA EN DIRECTO",0.00,9.50,0.00,5);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("PALOMITAS",0.00,1.00,0.00,5);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("BARRA LIBRE CATERING",27.00,0.00,0.00,6);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("HORA EXTRA BODAS KMO y O.E.P.",4.50,0.00,0.00,6);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("HORA EXTRA BODAS CATERING",6.50,0.00,0.00,6);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("DECORACIÓN FLORAL (CENTROS Y VARIOS)",0.00,0.00,-1.50,7);
INSERT INTO modificaciones (nombre, `add`, `replace`, `remove`, familia) VALUES ("CARTELERÍA",0.00,0.00,-0.80,7);

 -->